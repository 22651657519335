























































import Vue, { PropType } from "vue";

import { Business, Client, Role } from "@/types";
import clientSMSStoreModule from "@/store/modules/clientSMS";
import { createNamespacedHelpers } from "vuex";
import ClientSmsForm from "@/components/client/ClientSMSForm.vue";
import ActionButtons from "@/components/ActionButtons.vue";

const { mapGetters: smsGetters, mapActions: smsActions } =
  createNamespacedHelpers("CLIENT_SMS_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { ClientSmsForm, ActionButtons },
  name: "ClientSMSs",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Client",
        align: "start",
        sortable: true,
        value: "client",
      },
      {
        text: "Message",
        value: "message",
      },
      { text: "Date Sent", value: "createdAt" },
    ],
    addSMSDialog: false,
    options: {} as { page: number },
    apiUrl: VUE_APP_API_URL,
  }),
  computed: {
    ...smsGetters(["clientSMSPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role: "fetchSMSs",
    options: {
      handler() {
        this.fetchSMSs();
      },
      deep: true,
    },
  },
  methods: {
    ...smsActions(["fetchClientSMSList"]),
    fetchSMSs() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const param = this.client ? `&clientId=${this.client._id}` : "";
        this.fetchClientSMSList(
          `?businessId=${bid}&page=${this.options.page || 1}${param}`
        );
      }
    },
    closeModal() {
      this.fetchSMSs();
      this.addSMSDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_SMS_LIST")) {
      this.$store.registerModule("CLIENT_SMS_LIST", clientSMSStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_SMS_LIST");
  },
});
